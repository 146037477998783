<template>
  <div>
    <v-row style="max-width: 1100px" class="mx-auto pt-16 mt-8">
      <v-col class="pt-16 mt-16">
        <div style="max-width: 460px;" class="ml-8">
          <p class="mb-0 font-weight-bold" style="color: #00C853">{{$t('features.feature_long.1_title')}}</p>
          <h2>{{$t('features.feature_long.1_header')}}</h2>
          <p>{{$t('features.feature_long.1_text')}}</p>
        </div>
      </v-col>
      <v-col>
        <v-img
            max-width="450px"
            src="@/assets/financing.jpg"
            contain
            rounded
            class="rounded mx-8"
        >
        </v-img>
      </v-col>
    </v-row>

    <v-row style="max-width: 1100px" class="mx-auto pt-16">
      <v-col>
        <v-img
            max-width="450px"
            src="@/assets/flyer.jpg"
            contain
            rounded
            class="rounded mx-8"
        >
        </v-img>
      </v-col>
      <v-col class="pt-16 mt-16">
        <div style="max-width: 460px">
          <p class="mb-0 font-weight-bold" style="color: #FF8A65">{{$t('features.feature_long.2_title')}}</p>
          <h2>{{$t('features.feature_long.2_header')}}</h2>
          <p>{{$t('features.feature_long.2_text')}}</p>
        </div>

      </v-col>
    </v-row>

    <v-row style="max-width: 1100px" class="mx-auto pt-16">
      <v-col class="pt-16 mt-16">
        <div style="max-width: 460px;" class="ml-8">
          <p class="mb-0 font-weight-bold" style="color: #70BAFF">{{$t('features.feature_long.3_title')}}</p>
          <h2>{{$t('features.feature_long.3_header')}}</h2>
          <p>{{$t('features.feature_long.3_text')}}</p>
        </div>
      </v-col>
      <v-col>
        <v-img
            max-width="450px"
            src="@/assets/webpage.jpg"
            contain
            rounded
            class="rounded mx-8"
        >
        </v-img>
      </v-col>
    </v-row>

    <v-row style="max-width: 1100px" class="mx-auto pt-16">
      <v-col>
        <div class="mx-8 float-right" align="center" style="width:450px">
          <LazyYoutube
              src="https://youtu.be/ORf6aAG8xKw"
              aspectRatio="1080:1910"
              style="width: 180px; z-index: 2;"
          />
          <v-img
              max-width="450px"
              src="@/assets/color2.svg"
              contain
              rounded
              class="rounded mx-8"
              style="z-index: 1; margin-top: -214px"
          >
          </v-img>
        </div>
      </v-col>
      <v-col class="pt-16 mt-16">
        <div style="max-width: 460px">
          <p class="mb-0 font-weight-bold" style="color: #FF699C">{{$t('features.feature_long.4_title')}}</p>
          <h2>{{$t('features.feature_long.4_header')}}</h2>
          <p>{{$t('features.feature_long.4_text')}}</p>
        </div>
      </v-col>
    </v-row>

    <v-row style="max-width: 1100px" class="mx-auto pt-16 mt-8">
      <v-col class="pt-16 mt-16">
        <div style="max-width: 460px;" class="ml-8">
          <p class="mb-0 font-weight-bold" style="color: #00C853">{{$t('features.feature_long.5_title')}}</p>
          <h2>{{$t('features.feature_long.5_header')}}</h2>
          <p>{{$t('features.feature_long.5_text')}}</p>
          <a class="text-decoration-none" href="https://locations.kumscho.com" target="_blank">
            <v-btn
                color="rgb(0, 200, 83)"
                dark
                elevation="0"
                class="create-event-btn"
                rounded
            >
              {{ $t('features.feature_long.5_btn') }}
            </v-btn>
          </a>
        </div>
      </v-col>
      <v-col>
        <v-img
            max-width="450px"
            src="@/assets/locations.jpg"
            contain
            rounded
            class="rounded mx-8"
        >
        </v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {LazyYoutube} from "vue-lazytube";

export default {
  name: "FeatureListDetail",
  components: {
    LazyYoutube
  }
}
</script>

<style scoped>

</style>